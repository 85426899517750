import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image:"/images/service/revenue.png",
        title: 'Better Control Over Your Revenue',
        description: "Track your revenue effortlessly with the Summit Vista Owner Panel. Consolidate your earnings and expenses in one place, making it easy to monitor your business growth daily and make smart decisions to keep your properties profitable."
    },
    {
        image:"/images/service/cost.png",
        title: 'Stay Ahead in the Market',
        description: "Use the Summit Vista Owner Panel to set prices and plan growth proactively. This tool offers detailed market trends and competitor pricing, allowing you to adjust rates to maximize occupancy and revenue. With dynamic pricing, you always offer the best rates, making your properties more attractive to guests and boosting profitability."
    },
    {
        image: "/images/service/business.png",
        title: 'Your Partner in Making Business Decisions',
        description: 'Use live analytics to quickly improve your plans. This tool helps you change your business methods in the right way, making things run smoother and increasing your profits. Leverage this AI-based tool to make smart, well-planned decisions that keep your business ahead of the competition.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                            <img src={`${val.image}`} alt="card Images" />
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;