import React, { useState } from 'react';
import appStore from '../../store/appStore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Toastify'nin stillerini ekliyoruz

function ContactForm({ formStyle }) {
    const [result, showResult] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        email_name: 'Summit Vista',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const sendEmail = async (e) => {
        e.preventDefault(); 
        
        const response = await appStore.sendContact(formData); 
        
        if (response.success) {
            toast.success(response.message); // Success message
        } else {
            toast.error(response.message); // Error message
        }
        
        showResult(true); 
        
        setTimeout(() => {
            showResult(false);
        }, 5000);

        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            email_name: 'Summit Vista',
        });
    };

    return (
        <>
            <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
                <div className="form-group">
                    <input 
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input 
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input 
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        required
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input 
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        required
                        value={formData.subject}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <textarea 
                        name="message"
                        placeholder="Your Message"
                        required
                        value={formData.message}
                        onChange={handleChange}
                    >
                    </textarea>
                </div>

                <div className="form-group">
                    <button className="btn-default btn-large">Submit Now</button>
                </div> 
            </form>
            
            {/* Toast mesajlarını göstermek için ToastContainer */}
            <ToastContainer />
        </>
    );
}

export default ContactForm;
