import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";



const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}
                <div className="single-timeline mt--50" >
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <span className="date-of-timeline">Smart Technology</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <h2 className="title">Let's make your business or hotel a smart home.</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <p className="description">Experience the future of hospitality with our smart solutions powered by Alexa. Our innovative technology seamlessly integrates with Alexa, allowing you to create a smart environment for your business or hotel. Enhance guest experiences with voice-activated room controls, instant information access, and personalized services. From adjusting the lighting to providing concierge services, Alexa ensures a convenient and efficient stay for your guests, making your business a leader in modern hospitality.</p>
                                        </ScrollAnimation>
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/about#sumtech-smart" rel="noopener noreferrer">
                                                    <span>Learn More</span>
                                                </a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/smart.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-timeline mt--50" >
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">Smart Hospitality Management</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">Elevate Guest Experience with Cutting-Edge Technology in Hotels</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">Unlock the future of hospitality with Smart Hospitality Management where cutting-edge technology meets exceptional guest service. Elevate your hotel operations with seamless Digital Guest Experiences, enhance efficiency through Operational Excellence, and ensure top-tier Security and Data Protection. Embrace sustainability with our innovative Eco-Friendly Solutions, and stay ahead of the curve with advanced Analytics and Data Management.Transform your hotel into a smart, guest-centric, and sustainable establishment. Discover how our Smart Hospitality solutions can boost guest satisfaction, streamline operations, and drive profitability.</p>
                                        </ScrollAnimation>
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round"  href='/about#smart-apt' rel="noopener noreferrer">
                                                    <span>Learn More</span>
                                                </a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/airbnb.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-timeline mt--50" >
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">All-in-One Hospitality Platform</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">A Tailored Platform for Owners, Managers, and Guests</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">At Vista, we revolutionize the hospitality industry with a comprehensive platform designed to meet the diverse needs of hotel owners, Airbnb hosts, managers, and guests. Our platform includes three key panels—Owner, Manager, and Guest—each offering tailored features to enhance user experience. The Owner Panel provides real-time financial insights, the Manager Panel streamlines reservations and guest management, and the Guest Panel allows for personalized in-room experiences. Together, these tools empower smarter decisions, improve operational efficiency, and elevate guest satisfaction.
                                            </p>
                                        </ScrollAnimation>
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/propertyowner" rel="noopener noreferrer">
                                                    <span>Learn More</span>
                                                </a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/panel.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
