import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/hospitalty.png',
        title: 'Exceptional Guest Experience',
        description: 'We prioritize delivering an unparalleled guest experience through personalized services and innovative amenities that exceed expectations.'
    },
    {
        image: '/images/service/operations.png',
        title: 'Efficient Operations',
        description: 'Our team is dedicated to ensuring smooth and efficient operations, allowing you to focus on providing exceptional hospitality while we handle the rest.'
    },
    {
        image: '/images/service/support.png',
        title: "Comprehensive Support",
        description: "We're here for you around the clock. Our dedicated customer support is available 24/7 to assist you with any inquiries or needs during your journey."
        }
]

const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><a  dangerouslySetInnerHTML={{__html: val.title}}></a></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;