import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import AboutOne from '../elements/about/AboutOne';
import AboutOne2 from '../elements/about/AboutOne2';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Slider from '../elements/slider/slider';
import GoogleMap from "../elements/about/GoogleMap";
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import SlpitOne from '../elements/split/SlpitOne';
import Chat from '../common/chat_query';


const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <Chat/>
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-950 bg_image" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg15.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Our Company's About Details.</span></h3>
                                    </div>
                                    <h1 className="title display-one">We are a Special <br /> Hospitality Company.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

               
                <br />
                <div className="container" id="sumtech-smart">
                <AboutOne />            
                </div>                
                <Separator />
                <br />
                <br />

                {/* Start Elements Area  */}    
                <div >
                    <div className="container" id='smart-apt'>
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Solutions"
                                        title = "Smart Hotels We Manage"
                                    
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                            <AboutOne2 />    
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="wrapper plr--65 plr_sm--15">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Gallery."
                                    title = "Our Smart Rooms"
                                    description = ""
                                />
                            </div>
                        </div>
                        <Slider />
                    </div>
                </div>
                {/* End Elements Area  */}

                  {/* Start Elements Area  */}
                  <div className="col-8 mt_md--30 mt_sm--30" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <GoogleMap/>
                </div>
                <br/>
                <br/>
                <div className="rwt-callto-action-area rn-section-gapBottom ">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                
                <br/>
                {/* End Elements Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
