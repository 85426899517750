import React from "react";
import SEO from "../common/SEO";
import TimelineOne from "../elements/timeline/TimelineOne";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import BrandOne from "../elements/brand/BrandOne";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from '../elements/service/ServiceThree';


import Chat from '../common/chat_query';
const BannerData = [
    {
        image: "/images/bg/slider.png",
        title: "Unlocking Hospitality Potential.",
        description: "Our services are dedicated to unlocking the full potential of your hospitality property <br /> through expert leasing and management solutions."
    },
    {
        image: "/images/bg/slider2.png",
        title: "Maximum Income Minimum Stress!",
        description: "With our expert management, you can enjoy maximum income and minimum stress <br />  from your short-term rental property."
    },
    {
        image: "/images/bg/slider3.png",
        title: "Effortless Management Maximum Income!",
        description: "Experience effortless management and maximize your income with <br />  Summit Vista's intuitive property management panel."
    },
]

const TravelAgency = () => {
    
    //tıklamalarda tarayıcıda sayfa yenileme işlemini engellemek için kullanılır
    const scrollToSection = () => {
        const section = document.getElementById("target-section");
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            
            window.history.pushState(null, null, ' ');
        }
    };
    return (
        <>
            <Chat/>
            <SEO title="Special Hospitality" />
            <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-12">
                                            <div className="inner text-center">
                                                <h className="title" dangerouslySetInnerHTML={{__html: data.title}}></h>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <button className="btn-default" onClick={() => scrollToSection()}>View More</button>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Why We're the Right Choice."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}
                <Separator />
                {/* Start Timeline Area  */}
                <div id="target-section" className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Solutions"
                                        title = "Our Services."
                                        description = "We offer Hotel management for you, smart hotel service with Smart technology, an AI-powered panel where you can control your properties."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                            </div>
                        </div>
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
                    {/* Start Brand Area  */}
                    <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Awesome Client"
                                        title = "Endorsed by Leading Brands."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default TravelAgency;
