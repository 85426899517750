import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabTwo = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tab3.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tab4.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
        
                  
                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Smart AI Management and Security</h4>
                                        <p className="description">Let AI make your property management smarter. With Summit Vista, you can trust in our rock-solid security and continuous access to your data, all backed up by our secure cloud system.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Made Simple for All Users</h4>
                                        <p className="description">Summit Vista is designed for everyone involved in property management. Whether you are an owner, a manager, or a guest, our platform offers customized interfaces that improve decision-making, optimize operations, and elevate the overall rental experience.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabTwo;
