import React from 'react';
import VideoTwo from '../elements/video/VideoTwo';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import BrandThree from '../elements/brand/BrandThree';
import TeamThree from '../elements/team/TeamThree';
import PricingTwo from '../elements/pricing/PricingTwo';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import Slider from '../elements/slider/slider';
import Chat from '../common/chat_query';
var BlogListData = BlogClassicData.slice(0, 3);



const PopupData = [
    {
        id: "01",
       
        popupLink: [
            'https://www.youtube.com/watch?v=Vm-nI4N2-VU&t=2s',
        ],
    }
]



const EventConference = () => {
    return (
        <>
            <SEO title="Monetize Your Property" />
            <Chat/>
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-left inheader-transparent height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Monetize Your Property</h1>
                                    <p className="description color-white">Let Summit Vista transform your property into a year-round income generator.
                                    Explore our video to discover how Summit Vista can simplify your workload.</p>
                                    <div className="button-group mt--50 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">Contact Us <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                {PopupData.map((item) => (
                                    <div className="video-btn" key={item.id}>
                                        <VideoTwo imagename="./images/bg/slider3.png" galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    subtitle = "Why Not Make Managing Properties a Breeze?"
                                    title = "Empower Your Ownership Experience with Summit Vista"
                                />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "Discover the Future of Property Management"
                                    description = "Navigate through our diverse support materials to get solutions for your inquiries and access the assistance you're looking for."
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                       
                    </div>
                </div>
                {/* End Elements Area  */}

                          {/* Start Elements Area  */}
                          
                    <div className="container">
                        <AdvanceTabTwo />
                    </div>

                {/* End Elements Area  */}



                



                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}



                                
                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default EventConference;
