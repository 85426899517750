import React from 'react';
const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title"> About Company</h2>
                                <p>Experience the future of hospitality with our innovative smart solutions, powered by Alexa. Our technology seamlessly integrates voice-activated room controls, personalized services, and instant access to information, creating a smart environment for your hotel or business. Enhance guest experiences by offering convenience and efficiency, from adjusting lighting to providing concierge services, while staying ahead with advanced security and data protection. Embrace sustainability through our eco-friendly solutions, ensuring your establishment remains both modern and environmentally conscious.</p>
                                <p>Our all-in-one hospitality platform is designed to meet the diverse needs of owners, managers, and guests. The platform includes tailored features such as real-time financial insights for owners, streamlined reservation and guest management for managers, and personalized in-room experiences for guests. With these smart tools, you can boost operational efficiency, make informed decisions, and elevate overall guest satisfaction, transforming your hotel into a guest-centric, tech-forward destination.</p>
                            </div>
                            <div className="view-more-button mt--35">
                                    <a className="btn-default" href="https://smart.specialhospitality.com/">Visit Summit Smart</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
